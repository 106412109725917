import { StorageReference } from 'firebase/storage'
import { Timestamp } from 'firebase/firestore'

export enum TagColor {
    YELLOW = 'YELLOW',
    ORANGE = 'ORANGE',
    WHITE = 'WHITE',
    GRAY = 'GRAY',
    GREEN = 'GREEN',
    BLUE = 'BLUE',
    PURPLE = 'PURPLE',
    // PINK = "PINK",
    RED = 'RED',
}


export interface ETag {
    id: string,
    color: TagColor,
}

export interface AnimalLinkable {
    id: string,
    etag?: ETag, // opt
    photoUrl?: string, // opt
}

// linkable plus+
export interface CalfCardData extends AnimalLinkable {
    dob: Date, // what if only month and year?
    sex: AnimalSex,
}

export interface AnimalData extends CalfCardData {
    // is calf if null. also timeline event
    weaned?: boolean,
    weanCompleteDate?: Date, // what if only month and year?
    arrivalDate: Date,
    departureDate?: Date,
    // calf / detail profile
    mother?: AnimalLinkable,
    // female cow repro data
    reproductive?: AnimalReproData,
}

// female cow repro data
export interface AnimalReproData {
    dueDate?: Timestamp, // maybe date?
    calfPair?: CalfCardData,
}

export interface AnimalTimelineEvent<T = any> {
    id: string,
    actorId: string,
    type: string,
    timestamp: Date
    data: T,
}

export interface SeasonalEvalImages {
    north: EventImage,
    south: EventImage,
    east: EventImage,
    west: EventImage,
}

export interface SeasonalEvalData {
    season: string,
    year: string,
    ageMonths: number,
    images: SeasonalEvalImages,
}

export interface EventImage {
    ref: string,
    viewUrl: string
}

export enum AnimalSex {
    FEMALE = 'FEMALE',
    MALE = 'MALE',
    MALE_NEUTERED = 'MALE_NEUTERED'
}





