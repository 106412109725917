import {
    AnimalData,
    AnimalLinkable,
    AnimalSex,
    AnimalTimelineEvent,
    EventImage, SeasonalEvalData,
    SeasonalEvalImages,
    TagColor
} from './types'
import _mock, { randomInArray, randomNumberRange } from '../../../_mock'
import { customAlphabet, nanoid } from 'nanoid'
import { addMonths, sub } from 'date-fns'
import { isUndefined, omitBy, pick } from 'lodash'
import { getDownloadURL, ref } from 'firebase/storage'
import { fbs } from 'src/data/fire'

export interface TagColorHex {
    textColor: string,
    bgColor: string
}


const TagColorHexMap = {
    [TagColor.YELLOW]: {textColor: '#222', bgColor: '#fae57b'},
    [TagColor.ORANGE]: {textColor: '#222', bgColor: '#f8bc5d'},
    [TagColor.WHITE]: {textColor: '#222', bgColor: '#F6EDE0'},
    [TagColor.GRAY]: {textColor: '#222', bgColor: '#F6EDE0'},
    [TagColor.GREEN]: {textColor: '#222', bgColor: '#B7E7B9'},
    [TagColor.BLUE]: {textColor: '#222', bgColor: '#94c7e8'},
    [TagColor.PURPLE]: {textColor: '#222', bgColor: '#cf9ae1'},
    // [TagColor.PINK]: {textColor: "#222", bgColor: "#e38bb7"},
    [TagColor.RED]: {textColor: '#222', bgColor: '#e18686'},
}

export function getTagHexColors(tagColor: TagColor): TagColorHex {
    return TagColorHexMap[tagColor]
}

const unsplashPhotoIds = [
    '1595702339907-76fe4d25a3c5',
    '1520325143471-6969ad29c351',
    '1605822596985-3b5c5663844e',
    '1608671071793-db93efcf33df',
    '1609202100607-204bbf0d1caf',
    '1556817185-dbd9700ac971',
    '1633206327875-6d17672c804d',
    '1528143582951-ef14e49f4381',
    '1629725079296-c6ea01a8bfe6',
    '1527153857715-3908f2bae5e8',
    '1567879656049-f2265f23d8f8',
    '1595365691689-6b7b4e1970cf',
    '1545468800-85cc9bc6ecf7',
    '1618080206739-14e8ac105472',
    '1604860428762-f55cea62b7a0',
    '1593768697824-f31b967e6c55',
    '1607771459220-36163d88974c',
    '1531299192269-7e6cfc8553bb',
    '1594661387748-1155d9a8c7a6',
    '1609954111457-b377c9743c16',
    '1621163026372-72f4b4a958d8',
    '1559484101-9c6be5b712cd',
    '1580490145866-a488240c0536',
    '1583308148140-b7079097d5df',
    '1594731884638-8197c3102d1d',
    '1562863010-fabae474973d',
    '1634992798921-b968401c7b75',
    '1568184496328-88f3e702d84c',
    '1594796014671-8e4d728f7b7e',
    '1571554207497-a70ba03e5fe5',
    '1566053596110-26ecc4a03e59',
    '1600031473746-41103991b85b',
    '1565094465601-34f3b033c2eb',
]


const tagColors = [
    TagColor.YELLOW,
    TagColor.YELLOW,
    TagColor.YELLOW,
    TagColor.YELLOW,
    TagColor.YELLOW,
    TagColor.ORANGE,
    TagColor.WHITE,
    TagColor.GREEN,
    TagColor.BLUE,
]

const sexOptions = [
    AnimalSex.MALE_NEUTERED,
    AnimalSex.MALE_NEUTERED,
    AnimalSex.MALE_NEUTERED,
    AnimalSex.MALE_NEUTERED,
    AnimalSex.MALE_NEUTERED,
    AnimalSex.MALE,
    AnimalSex.FEMALE,
    AnimalSex.FEMALE,
    AnimalSex.FEMALE,
    AnimalSex.FEMALE,
    AnimalSex.FEMALE,
    AnimalSex.FEMALE,
]

export const generateAnimals = (count: number) => [...Array(count)].flatMap<AnimalData>(() => {
    const {animal, calf} = generateAnimal()
    if (calf) {
        return [animal, calf]
    } else {
        return [animal]
    }
})
const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
const lowercase = 'abcdefghijklmnopqrstuvwxyz'
const numbers = '0123456789'
const nanoId = customAlphabet(numbers + lowercase + uppercase, 10)

let count = 10
const generateAnimal = (mother?: AnimalLinkable): { animal: AnimalData, calf?: AnimalData } => {
    // eslint-disable-next-line no-plusplus
    count++
    // const dob = subDays(new Date(), index * 25)
    const ageMonths = mother ? randomNumberRange(0, 12) : randomNumberRange(12, 52)
    const dob = sub(new Date(), {
        months: ageMonths,
        days: randomNumberRange(0, 8),
        hours: randomNumberRange(0, 8),
        minutes: randomNumberRange(0, 59)
    })


    const photoId = randomInArray(unsplashPhotoIds)

    // hopefully this gives us a range of 2 to 5 digit etags.
    const etagPrefix = Math.max(randomNumberRange(-50, 255), 0) * 100
    const linkableCard: AnimalLinkable = {
        id: nanoId(),
        etag: {id: `${etagPrefix + count}`, color: randomInArray(tagColors)},
        photoUrl: `https://images.unsplash.com/photo-${photoId}?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=250&w=250`,
    }

    const sex = randomInArray(sexOptions)
    const weanCompleteDate = !mother ? addMonths(dob, 12) : undefined
    const isBreedingFemale = (sex === AnimalSex.FEMALE && !!weanCompleteDate)
    const {animal: calf} = (isBreedingFemale && randomInArray([true, true, false])) ?
        generateAnimal(linkableCard) : {animal: undefined}
    // const dueDate = (isBreedingFemale && !calf && randomInArray([true, false])) ? '06/12/23' : undefined

    return {
        animal: omitBy({
            ...linkableCard,
            dob,
            weanCompleteDate,
            weaned: !!weanCompleteDate,
            sex,
            mother,
            reproductive: calf
                ?  {
                        calfPair: calf
                            ? pick(calf, ['id', 'etag', 'photoUrl', 'sex', 'dob'])
                            : undefined,
                    }
                : undefined
        }, isUndefined) as any,
        calf
    }
}


export const generateEvents = async (actorId: string): Promise<AnimalTimelineEvent<any>[]> => {

    return [
        {
            id: nanoid(),
            actorId,
            type: 'seasonal_eval',
            data: await generateSeasonalEvalData('2022', 'Fall', 15),
            timestamp: _mock.time(124)
        },
        {
            id: nanoid(),
            actorId,
            type: 'wellness_check',
            data: null,
            timestamp: _mock.time(136)
        },
        {
            id: nanoid(),
            type: 'seasonal_eval',
            actorId,
            data: await generateSeasonalEvalData('2022', 'Summer', 12),
            timestamp: _mock.time(154)
        },
    ]
}

const generateSeasonalEvalData = async (year: string, season: string, ageMonths: number): Promise<SeasonalEvalData> => ({
    year, season, ageMonths,
    images: await generateSeasonalEvalImages()
})

let cachedImages: SeasonalEvalImages
const generateSeasonalEvalImages = async (): Promise<SeasonalEvalImages> => {
    if (!cachedImages) {
        cachedImages = {
            north: await eventImageFromRefPath('/testing/eval_media/browncow_1.jpeg'),
            east: await eventImageFromRefPath('/testing/eval_media/browncow_2.jpeg'),
            south: await eventImageFromRefPath('/testing/eval_media/browncow_4.jpeg'),
            west: await eventImageFromRefPath('/testing/eval_media/browncow_3.jpeg'),
        }
    }

    return cachedImages
}

const eventImageFromRefPath = async (path: string): Promise<EventImage> => ({
    ref: path,
    viewUrl: await getDownloadURL(ref(fbs, path))
})
