// components
import SvgColor from '../../../components/svg-color';
import {useContext, useMemo} from "react";
import {FarmPaths, FarmPathsContext} from "../../../routes/paths";
import {NavListProps} from "../../../components/nav-section";

// ----------------------------------------------------------------------

const icon = (name: string) => (
    <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{width: 1, height: 1}}/>
);

const ICONS = {
    user: icon('ic_user'),
    ecommerce: icon('ic_ecommerce'),
    analytics: icon('ic_analytics'),
    dashboard: icon('ic_dashboard'),
    menuItem: icon('ic_menu_item'),
    chat: icon('ic_chat'),
    kanban: icon('ic_kanban'),
    banking: icon('ic_banking'),
    booking: icon('ic_booking'),
    file: icon('ic_file'),
    label: icon('ic_label'),
    blank: icon('ic_blank'),
};


export const useNavConfig = () => {

    const {local: lp, localToExternal}: FarmPaths = useContext(FarmPathsContext)

    return useMemo((): {
        subheader: string;
        items: NavListProps[];
    }[] => [
        // GENERAL
        // ----------------------------------------------------------------------
        {
            subheader: 'Pasture Insights',
            items: [
                {
                    title: 'Cattle', icon: ICONS.menuItem, path: '/herd', expanded: true,
                    children: [
                        {title: 'Population Metrics', local: lp.metrics, path: localToExternal(lp.metrics),},
                        {title: 'Roster', local: lp.records, path: localToExternal(lp.records),},
                    ],
                },
            ],
        },
        {
            subheader: 'Team',
            items: [
                {title: 'Channels', local: lp.channels, path: localToExternal(lp.channels), icon: ICONS.chat},
            ],
        },
        {
            subheader: 'Development',
            items: [
                {
                    title: 'Work In Progress', icon: ICONS.booking, path: localToExternal(lp.activity),
                    children: [
                        {
                            title: 'Activity',
                            local: lp.activity,
                            path: localToExternal(lp.activity),
                            // icon: ICONS.dashboard
                        },
                        {
                            title: 'Pasture',
                            local: lp.pasture,
                            path: localToExternal(lp.pasture),
                            // icon: ICONS.label
                        },
                        {
                            title: 'Accounting',
                            local: lp.accounting,
                            path: localToExternal(lp.accounting),
                            // icon: ICONS.analytics
                        },
                        {
                            title: 'Projects',
                            local: lp.projects,
                            path: localToExternal(lp.projects),
                            // icon: ICONS.file
                        },
                    ],
                },
            ],
        },

        // MANAGEMENT
        // ----------------------------------------------------------------------
        // {
        //   subheader: 'management',
        //   items: [
        //     {
        //       title: 'user',
        //       path: PATH_DASHBOARD.user.root,
        //       icon: ICONS.user,
        //       children: [
        //         { title: 'Four', path: PATH_DASHBOARD.user.four },
        //         { title: 'Five', path: PATH_DASHBOARD.user.five },
        //         { title: 'Six', path: PATH_DASHBOARD.user.six },
        //       ],
        //     },
        //   ],
        // },
    ], [lp, localToExternal])
}

