import { Helmet } from 'react-helmet-async'
// sections
import { PrivacyPolicy } from 'src/sections/landing/PrivacyPolicy'

// ----------------------------------------------------------------------

export default function PrivacyPolicyPage() {
    return (
        <>
            <Helmet>
                <title> Privacy Policy | Moooru</title>
            </Helmet>

            <PrivacyPolicy/>
        </>
    )
}
