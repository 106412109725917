import {initializeApp} from "firebase/app";
import {
    collection,
    doc,
    DocumentData,
    FirestoreDataConverter,
    getFirestore,
    QueryDocumentSnapshot,
    SnapshotOptions,
    Timestamp
} from "firebase/firestore";
import {getAuth} from "firebase/auth";
import {AnimalData, AnimalReproData, AnimalTimelineEvent} from "./farm/animals";
import {isUndefined, omit, omitBy} from "lodash";
import {getStorage} from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyCgdlVA1z_ey96nOyxxadCF3mpC12KmwW8",
    authDomain: "mooru-prod.firebaseapp.com",
    projectId: "mooru-prod",
    storageBucket: "mooru-prod.appspot.com",
    messagingSenderId: "852395133723",
    appId: "1:852395133723:web:c3082f35a95f511cbba1be",
    measurementId: "G-X4HRXCH4ZT"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const fbs = getStorage(app);
export const db = getFirestore(app)
export const contactFormCollectionRef = () => collection(db as any, "early-access-contacts")
export const userDocRef = (userId: string) => doc<{ defaultFarm: { id: string } }>(db as any, "users", userId)
export const farmDocRef = (farmId: string) => doc<any>(db as any, "farms", farmId)

export const farmAnimalsCollectionRef = (farmId: string) => collection(farmDocRef(farmId), "animals")
    .withConverter(animalDataConverter)
export const farmAnimalEventsCollectionRef = (farmId: string, animalId: string) =>
    collection(farmDocRef(farmId), "animals", animalId, "events")
        .withConverter(animalTimelineEventConverter)
export const fireAuth = getAuth(app)


const animalDataConverter: FirestoreDataConverter<AnimalData> = {
    toFirestore(aData: AnimalData): DocumentData {
        // if (aData.mother) {
        //     return {...omitBy(aData, isUndefined), mother: omitBy(aData.mother, isUndefined), dob: Timestamp.fromDate(aData.dob)}
        // }
        // if (aData.calfPair) {
        //     return {...omitBy(aData, isUndefined), calfPair: omitBy(aData.calfPair, isUndefined), dob: Timestamp.fromDate(aData.dob)}
        // }
        return {...omitBy(aData, isUndefined), dob: Timestamp.fromDate(aData.dob)}
    },
    fromFirestore(
        snapshot: QueryDocumentSnapshot<DocumentData>,
        options?: SnapshotOptions
    ): AnimalData {
        const data = snapshot.data(options) as AnimalData
        if (data.reproductive?.calfPair) {
            const orig = data.reproductive.calfPair
            const reproductive: AnimalReproData = {calfPair: {...orig, dob: (orig.dob as any as Timestamp).toDate()}}
            return {...data, dob: (data.dob as any as Timestamp).toDate(), reproductive}
        } else {
            return {...data, dob: (data.dob as any as Timestamp).toDate()}
        }
    }
};

const animalTimelineEventConverter: FirestoreDataConverter<AnimalTimelineEvent> = {
    toFirestore(event: AnimalTimelineEvent): DocumentData {
        return {
            ...event,
            timestamp: Timestamp.fromDate(event.timestamp)
        }
    },
    fromFirestore(
        snapshot: QueryDocumentSnapshot<DocumentData>,
        options?: SnapshotOptions
    ): AnimalTimelineEvent {
        const data = snapshot.data(options) as any
        return {
            ...data,
            timestamp: (data.timestamp as Timestamp).toDate()
        }
    }
};


