// @mui
import { Box, IconButton, Stack } from '@mui/material'
// components
//
import { StyledContent, StyledRoot, StyledSection } from './styles'
import Iconify from 'src/components/iconify'
import { Link as RLink } from 'react-router-dom'

// ----------------------------------------------------------------------

type Props = {
    children: React.ReactNode;
};

export default function LoginLayout({children}: Props) {
    return (
        <StyledRoot>
            <StyledContent>
                <Stack sx={{width: 1, maxWidth: 400, mx: 'auto', minWidth: 320}}> {children} </Stack>
            </StyledContent>
            <Box sx={{position: 'absolute', p:2}}>
                <IconButton to={'/'} component={RLink}>
                    <Iconify icon={'mdi:arrow-left'} sx={{height: 30, width: 30, color:'#CCC'}}></Iconify>
                </IconButton>
            </Box>
            <StyledSection/>
        </StyledRoot>
    )
}
