import merge from 'lodash/merge';
import {useMemo} from 'react';
// @mui
import {alpha, ThemeProvider, createTheme, useTheme} from '@mui/material/styles';
import {useSettingsContext} from "./SettingsContext";

// ----------------------------------------------------------------------

type Props = {
    children: React.ReactNode;
};

export default function ThemeColorPresets({ children }: Props) {
    const outerTheme = useTheme();

    // const { presetsColor } = useSettingsContext();

    const themeOptions = useMemo(
        () => ({
            // palette: {
            //     primary: outerTheme.palette.primary,
            // },
            customShadows: {
                primary: `0 8px 16px 0 ${alpha(outerTheme.palette.primary.main, 0.24)}`,
            },
        }),
        [outerTheme.palette.primary]
    );

    const theme = createTheme(merge(outerTheme, themeOptions));

    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
