import { Helmet } from 'react-helmet-async';
// sections
import AboutUs from 'src/sections/landing/AboutUs'

// ----------------------------------------------------------------------

export default function AboutUsPage() {
  return (
    <>
      <Helmet>
          <title> About Us | Moooru</title>
      </Helmet>

      <AboutUs/>
    </>
  );
}
