import {createContext, ReactNode, useCallback, useContext, useMemo} from 'react';
// hooks
import useLocalStorage from '../../hooks/useLocalStorage';
//
import {defaultSettings} from './config-setting';
import {SettingsContextProps} from './types';
import {defaultPreset, getPresets, presetsOption} from './presets';

// ----------------------------------------------------------------------

const initialState: SettingsContextProps = {
    ...defaultSettings,
    // Mode
    onToggleMode: () => {
    },
    onChangeMode: () => {
    },
    // Layout
    onToggleLayout: () => {
    },
    onChangeLayout: () => {
    },
};

// ----------------------------------------------------------------------

export const SettingsContext = createContext(initialState);

export const useSettingsContext = () => {
    const context = useContext(SettingsContext);

    if (!context) throw new Error('useSettingsContext must be use inside SettingsProvider');

    return context;
};

// ----------------------------------------------------------------------

type SettingsProviderProps = {
    children: ReactNode;
};

export function SettingsProvider({children}: SettingsProviderProps) {
    const [settings, setSettings] = useLocalStorage('settings', defaultSettings);

    // Mode
    const onToggleMode = useCallback(() => {
        const themeMode = settings.themeMode === 'light' ? 'dark' : 'light';
        setSettings({...settings, themeMode});
    }, [setSettings, settings]);

    const onChangeMode = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const themeMode = event.target.value;
            setSettings({...settings, themeMode});
        },
        [setSettings, settings]
    );

    // Layout
    const onToggleLayout = useCallback(() => {
        const themeLayout = settings.themeLayout === 'vertical' ? 'mini' : 'vertical';
        setSettings({...settings, themeLayout});
    }, [setSettings, settings]);

    const onChangeLayout = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const themeLayout = event.target.value;
            setSettings({...settings, themeLayout});
        },
        [setSettings, settings]
    );

    const memoizedValue = useMemo(
        () => ({
            ...settings,
            // Mode
            onToggleMode,
            onChangeMode,

            themeContrast: 'bold',

            // Layout
            onToggleLayout,
            onChangeLayout,
        }),
        [
            settings,
            // Mode
            onToggleMode,
            onChangeMode,
            // Layout
            onToggleLayout,
            onChangeLayout,
        ]
    );

    return <SettingsContext.Provider value={memoizedValue}>{children}</SettingsContext.Provider>;
}
