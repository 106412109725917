// scroll bar
import 'simplebar/src/simplebar.css';

import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------
import {BrowserRouter} from 'react-router-dom';
import {HelmetProvider} from 'react-helmet-async';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import SnackbarProvider from './components/snackbar';
import ScrollToTop from './components/scroll-to-top';
import {MotionLazyContainer} from './components/animate';
import {SettingsProvider, ThemeSettings} from './components/settings';

// Check our docs
// https://docs.minimals.cc/authentication/ts-version
import {AuthProvider} from './auth/AuthContext';

// ----------------------------------------------------------------------

export default function App() {
    return (
        <AuthProvider>
            <HelmetProvider>
                <SettingsProvider>
                    <BrowserRouter>
                        <ScrollToTop/>
                        <MotionLazyContainer>
                            <ThemeProvider>
                                <ThemeSettings>
                                    <SnackbarProvider>
                                        <Router/>
                                    </SnackbarProvider>
                                </ThemeSettings>
                            </ThemeProvider>
                        </MotionLazyContainer>
                    </BrowserRouter>
                </SettingsProvider>
            </HelmetProvider>
        </AuthProvider>
    );
}
