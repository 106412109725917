import {PropsWithChildren} from "react";
import {FarmAnimalsContext} from "src/data/farm/animals/context";
import LoadingScreen from "../../../components/loading-screen";
import {useFarmAnimals} from "../../../data/farm/animals";


export default function FarmAnimalProvider({children}: PropsWithChildren) {
    const farmAnimals = useFarmAnimals()

    if (!farmAnimals.isInitialized) {
        return <LoadingScreen/>;
    }

    return (
        <FarmAnimalsContext.Provider value={farmAnimals}>{children}</FarmAnimalsContext.Provider>
    )
}
