import ReactDOM from 'react-dom/client'
//
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'


import * as FullStory from '@fullstory/browser'

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

FullStory.init({orgId: 'o-1NN8EV-na1'})
root.render(<App/>)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()


// If we ever want to add hotjar back
// import Hotjar from '@hotjar/browser'
// const siteId = 3575544;
// const hotjarVersion = 6;
// Hotjar.init(siteId, hotjarVersion);
