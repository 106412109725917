// @mui
import { Avatar, Box, Container, Hidden, Link, Stack, Typography } from '@mui/material'
// auth
// layouts
//
import { Link as RLink } from 'react-router-dom'
import { PublicNavBar } from 'src/sections/landing/comp/NavBar'
import React, { FC } from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import AmberWaves from 'src/assets/illustrations/AmberWaves'
import { Footer } from 'src/sections/landing/comp/Footer'

// ----------------------------------------------------------------------

export default function AboutUs() {
    return (
        <Box sx={{
            // height: '100vh',
            // display: 'flex',
            // flexDirection: 'column',
            background: '#EEE1D0',
            position: 'relative',
            overflow: 'hidden',
            maxWidth: '100vw',
        }}>

            <PublicNavBar/>
            <Container sx={{my: 5}}>
                <Grid container columnSpacing={6} alignItems={'flex-start'} direction={'row-reverse'}
                      justifyContent={'center'}>
                    <Grid xs={10} sm={7} md={5} lg={5}>
                        <img width={'100%'} src="/assets/landing/about_us_horse.png" alt="about us"
                             style={{paddingTop: 0}}/>
                    </Grid>
                    <Grid xs={12} sm={12} md={7} lg={7} sx={{my: 4}}>
                        <Typography variant={'h2'} color={'primary'} sx={{mb: 6}}>About Us</Typography>
                        <Typography variant={'body1'} sx={{fontSize: '1.1rem', lineHeight: '2rem'}}>
                            Moooru is dedicated to the farmers who work tirelessly to provide for their families, who
                            tend to their animals with care and dignity, who steward their land with integrity, who
                            produce healthy, nutritious food for their community.
                            <br/><br/>
                            A well-designed tool can make a tough job safer, easier, more enjoyable, and more
                            productive.
                            <Typography component={'span'}
                                        sx={{fontWeight: 800, fontSize: '1.15rem', lineHeight: '2rem'}}>
                                &nbsp;Moooru builds thoughtfully designed tools to empower farmers&nbsp;
                            </Typography>
                            to better serve their families whom they provide for, the animals they look after, the
                            land they steward, and the communities they feed.
                        </Typography>
                    </Grid>

                </Grid>
                <TeamSection/>
            </Container>
            <Footer/>
        </Box>
    )
}


const TeamSection: FC = () => {
    return (
        <Box sx={{textAlign: 'center', my: {xs: 5,sm: 10}}}>
            <Typography variant={'h2'} color={'primary'} sx={{mb: 4}}>Team</Typography>
            <Grid container sx={{mx: 'auto'}} justifyContent={'center'} rowSpacing={4}>
                <TeamMember name={'Roy Neely IV'} title={'Product Design & Development'}
                            pic={'/assets/landing/team-roy-iv.jpg'}/>
                <TeamMember name={'Jetta Neely'} title={'Regenerative Pasture Management Consultant'}/>
                <TeamMember name={'Roy Neely II'} title={'Regenerative Pasture Management Consultant'}/>
            </Grid>
        </Box>
    )
}

const TeamMember: FC<{ name: string, pic?: string, title: string }> = ({pic, name, title}) => {
    return (
        <Grid xs={6} sm={4} md={3}>
            <Box sx={{mx: 'auto', textAlign: 'center'}}>
                <Avatar src={pic} sx={{height: {xs: 140,sm: 180}, width: {xs: 140,sm: 180}, mx: 'auto', mt: 3}}/>
                <Typography variant={'h5'} sx={{my: 2}}>{name}</Typography>
                <Typography variant={'body2'} sx={{my: 2, maxWidth: 200, mx: 'auto'}}>{title}</Typography>

            </Box>
        </Grid>
    )
}