import { Helmet } from 'react-helmet-async';
// sections
import Landing from '../sections/landing/Landing';

// ----------------------------------------------------------------------

export default function LandingPage() {
  return (
    <>
      <Helmet>
        <title>Moooru | Herd & Pasture Insights</title>
      </Helmet>

      <Landing />
    </>
  );
}
