// @mui
import { Box, Container, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
// auth
// layouts
//
import React, { FC } from 'react'
import { m } from 'framer-motion'
import { Footer } from 'src/sections/landing/comp/Footer'
import { Pricing } from 'src/sections/landing/Pricing'
import { SheepComingSoon } from 'src/sections/landing/SheepComingSoon'
import { MobileAppBlock } from 'src/sections/landing/MobileAppBlock'
import { Hero } from 'src/sections/landing/Hero'


// ----------------------------------------------------------------------

export default function Landing() {
    return (
        <Box sx={{background: '#EEE1D0', width: '100vw', overflowX: 'hidden'}}>
            <Hero/>
            <Box sx={{pt: 4, background: '#FFF', pointerEvents: 'none'}}>
                <BasicImgTextLayout
                    text={
                        <SectionWordsTemplate
                            category={'Animal Health & Wellness'}
                            title={'Happy Herd, Happy Cowboy'}
                            text={'Secure your peace of mind knowing that every animal in your care is happy and healthy. Moooru empowers you to proactively monitor the health and wellness of your herd, ensuring timely attention to those in need. '}
                        />
                    }
                    graphic={<img width={'100%'} src="/assets/landing/animal_health.png" alt="animal health"/>}
                />
                <BasicImgTextLayout
                    text={
                        <SectionWordsTemplate
                            category={'Team Synchronization'}
                            title={'None of us are as smart as all of us'}
                            text={'Timely, personalized updates keep each member of your farm team in the loop. Built to foster real-time collaboration and harness the collective expertise of your family and your farm team.'}
                        />
                    }
                    graphic={<img width={'100%'} src="/assets/landing/team-sync.png" alt="team sync"/>}
                    reverse={true}
                />
                <Box sx={{background: '#EEE1D0', py: 8}}>
                    <BasicImgTextLayout
                        text={
                            <SectionWordsTemplate
                                category={'Herd & Pasture Insights'}
                                title={'Ensure your herd is the perfect size'}
                                text={'Take the guesswork out of cattle management. ' +
                                    'Moooru aggregates your animal data and pasture data, along with market trends, forecasted hay costs, and regional weather data ' +
                                    'to help you make data-backed decisions on which animals should stay in the herd and which animals should go to market.'}
                            />
                        }
                        graphic={
                            <img width={'100%'} src="/assets/landing/herd_metrics.png" alt="herd metrics"/>
                        }
                    />
                </Box>
                <Box sx={{background: '#38474C', py: 1, color: '#96A7A8'}}>
                    <MobileAppBlock/>
                </Box>
                <BasicImgTextLayout
                    text={
                        <SectionWordsTemplate
                            category={'Pasture Management'}
                            title={'Lush Soil, Lush Pasture'}
                            text={'Surfacing critical insights from your pasture, Moooru can help you strategically plan grazing rotations, nurture soil health, and build flourishing pasture for generations to come.'}
                        />
                    }
                    graphic={
                        <img width={'100%'} src="/assets/landing/pasture_tools.png" alt="pasture tools"/>
                    }
                    reverse={true}
                />
                <BasicImgTextLayout
                    text={
                        <SectionWordsTemplate
                            category={'Easy Peasy'}
                            title={'All you need is your voice'}
                            text={'Using the latest advancements in machine learning, Moooru pulls critical details from your natural language and intelligently suggests the appropriate record updates. Keep your attention on the job at hand.'}
                        />
                    }
                    graphic={
                        <img width={'90%'} src="/assets/landing/voice_notes.png" alt="voice notes"/>
                    }
                />
                <SheepComingSoon/>
            </Box>

            <Pricing/>
            <Footer showCows={true}/>
        </Box>
    )
}


const BasicImgTextLayout: FC<{
    text: React.ReactNode,
    graphic: React.ReactNode,
    reverse?: boolean
}> = ({
          text,
          graphic,
          reverse
      }) => {
    return (
        <Container sx={{maxWidth: 1300}}>
            <Grid container spacing={6} alignItems={'center'} justifyContent={'center'}
                  sx={{my: 5, flexDirection: reverse ? 'row-reverse' : 'row'}} columns={{xs: 12, lg: 11}}>
                <Grid
                    xs={12}
                    md={6}

                    lg={5}
                    component={m.div}
                    initial={{opacity: 0, x: reverse ? 50 : -50}}
                    whileInView={{opacity: 1, x: 0}}
                    viewport={{once: true}}
                    transition={{duration: .5, delay: .3}}
                >
                    {graphic}
                </Grid>
                <Grid
                    xs={12}
                    sm={10}
                    md={6}
                    lg={6}
                    component={m.div}
                    initial={{opacity: 0, x: reverse ? -50 : 50}}
                    whileInView={{opacity: 1, x: 0}}
                    viewport={{once: true}}
                    transition={{duration: .7}}
                >
                    {text}

                </Grid>
            </Grid>
        </Container>
    )
}

const SectionWordsTemplate: FC<{ title: string, category: string, text: string }> = ({title, category, text}) => {
    return (
        <Box>
            <Typography variant={'subtitle1'} color={'primary'}
                        sx={{textTransform: 'uppercase'}}>{category}</Typography>
            <Typography variant={'h3'} sx={{mt: 2}}>{title}</Typography>
            <Typography variant={'body1'} sx={{mt: 2}}>{text}</Typography>
        </Box>
    )
}