// @mui
import { Box, Container, Hidden, Link, Stack, Typography } from '@mui/material'
// auth
// layouts
//
import React, { FC } from 'react'
import AmberWaves from 'src/assets/illustrations/AmberWaves'
import Logo from 'src/components/logo'
import Wordmark from 'src/components/logo/Wordmark'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

// ----------------------------------------------------------------------

export const Footer: FC<{ showCows?: boolean }> = ({showCows}) => {
    const theme = useTheme()
    const mdUp = useMediaQuery(theme.breakpoints.up('md'))
    const smUp = useMediaQuery(theme.breakpoints.up('sm'))
    const mobile = useMediaQuery(theme.breakpoints.only('xs'))
    return (
        <Box sx={{pt: 10, position: 'relative'}}>
            <Box sx={{
                height: mobile ? '14vh' : '20vh',
                width: '100%',
                minWidth: 800,
                position: 'relative',
                top: 5,
                background: 'url("/assets/landing/blue-hills.svg") no-repeat 50% 50%',
                backgroundSize: '100% auto'
            }}/>
            <Box sx={{background: '#013B49', position: 'relative', zIndex: 100, pt: mobile?8:4, pb: 12}}>
                <Container>
                    <Stack direction={'row'} spacing={20} justifyContent={'center'}>
                        <Logo sx={{height: 40, width: 40}} customColor={'#7AAFB9'}/>
                        <Wordmark sx={{mx: 2, height: 40, width: 80, mt: .5}} customColor={'#7AAFB9'}/>
                    </Stack>
                    <Stack direction={mobile ? 'column' : 'row'} spacing={mobile ? 2 : 5} justifyContent={'center'}
                           sx={{mt: 3, fontWeight: 600, textAlign: 'center'}}>
                        <Link href={'/about-us'} sx={{color: '#7AAFB9'}}>About Us</Link>
                        <Link href={'/terms'} sx={{color: '#7AAFB9'}}>Terms of Service</Link>
                        <Link href={'/privacy-policy'} sx={{color: '#7AAFB9'}}>Privacy Policy</Link>
                    </Stack>
                    <Stack direction={'column'} spacing={4} justifyContent={'center'} sx={{mt: 6, textAlign:'center'}}>
                        {/*<Stack target={'_blank'} href={'https://nicemonkey.studio'} direction={'row'} spacing={2} alignItems={'center'} component={Link}>*/}
                        {/*    <img src="/assets/landing/nicemonkey.svg" alt="Nice Monkey Studio Logo"*/}
                        {/*         style={{height: 33}}/>*/}
                        {/*    <Typography sx={{color: '#7AAFB9', fontWeight:600}}>NICE MONKEY</Typography>*/}
                        {/*</Stack>*/}
                        <Link href={'mailto:info@moooru.com'} sx={{color: '#5f8a91', fontWeight: 700}}>info@moooru.com</Link>
                        <Typography sx={{color: '#5f8a91'}}>© Moooru Pastures LLC
                            - {new Date().getFullYear()}</Typography>
                    </Stack>

                </Container>
            </Box>
            <Box
                sx={{
                    height: 200,
                    position: 'absolute',
                    display: showCows ? 'block' : 'none',
                    top: mobile ? -60 : -50,
                    left: mobile ? '15%' : '30%',

                }}
            >
                <img src="/assets/landing/brown-calf.svg" alt="Brown Calf"
                     style={{objectFit: 'contain', height: '100%'}}/>
            </Box>
            <Hidden mdDown>
                <Box
                    sx={{
                        height: 140,
                        position: 'absolute',
                        display: showCows ? 'block' : 'none',
                        top: -15,
                        left: '73%',

                    }}
                >
                    <img src="/assets/landing/mom-pop-cows.svg" alt="Cows"
                         style={{objectFit: 'contain', height: '100%'}}/>
                </Box>
            </Hidden>

            {/*<Box sx={{height: '26vh', width: '100%', position:'relative', top: 5}}>*/}
            {/*    <AmberWaves/>*/}
            {/*</Box>*/}
        </Box>
    )
}