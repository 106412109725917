// @mui
import { Box, Container, Stack, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import React, { FC } from 'react'
import { m } from 'framer-motion'


// ----------------------------------------------------------------------

export const MobileAppBlock: FC = () => {
    return (
        <Container>
            <Grid container
                  spacing={5}
                  sx={{
                      alignItems: 'center',
                      justifyContent: 'center',
                      my: 5,
                  }}>
                <Grid
                    xs={10}
                    sm={6}
                    lg={6}
                    sx={{display: 'flex', justifyContent: 'flex-end'}}
                    component={m.div}
                    initial={{opacity: 0, y: 50}}
                    whileInView={{opacity: 1, y: 0}}
                    viewport={{once: true}}
                    transition={{duration: .7, delay: .2}}
                >
                    <img width={'auto'} src="/assets/landing/mobile-app.png" alt="mobile app" style={{maxHeight: 600}}/>
                </Grid>
                <Grid
                    lg={6}
                >
                    <Box>
                        <Typography variant={'subtitle1'}
                                    sx={{textTransform: 'uppercase'}}>Moooru Mobile App</Typography>
                        <Typography variant={'h3'} sx={{mt: 2}}>Designed for the pasture</Typography>
                        <Stack spacing={5} sx={{mt: 6}}>
                            <MobileAppFeatureHighlight
                                title={'Seamless Field Notes'}
                                text={'Easily record animal health observations, interventions, and pasture assessments.'}
                                icon={'/assets/landing/notes.svg'}
                                index={1}
                            />
                            <MobileAppFeatureHighlight
                                title={'Stay Up to Date'}
                                text={'Realtime data streams keep your team in sync and up to date.'}
                                icon={'/assets/landing/notifications.svg'}
                                index={2}
                            />
                            <MobileAppFeatureHighlight
                                title={'Offline Ready'}
                                text={'No Internet, No Problem. Updates will sync to the cloud when a connection becomes available.'}
                                icon={'/assets/landing/offline.svg'}
                                index={3}
                            />
                        </Stack>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    )
}

const MobileAppFeatureHighlight: FC<{
    title: string, text: string, icon: string, index: number
}> = ({
          title,
          text,
          icon,
          index
      }) => {
    return (
        <Box sx={{display: 'flex', justifyContent: 'flex-start'}}
             component={m.div}
             initial={{opacity: 0, x: -50}}
             whileInView={{opacity: 1, x: 0}}
             viewport={{once: true}}
             transition={{duration: .7, delay: index * .1}}
        >
            <Box sx={{textAlign: 'right'}}>
                <img src={icon} alt={title} style={{width: 60}}/>
            </Box>
            <Box sx={{width: '100%', maxWidth: 350, ml: 4}}>
                <Typography variant={'h6'}>{title}</Typography>
                <Typography variant={'body2'}>{text}</Typography>
            </Box>
        </Box>
    )
}