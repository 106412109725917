import { useCallback, useContext, useMemo } from 'react'
import { chunk, groupBy, keyBy } from 'lodash'
import { AnimalData, AnimalLinkable, AnimalSex, AnimalTimelineEvent } from './types'
import { generateAnimals, generateEvents } from './utils'
import { FarmMetaContext } from '../meta'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { db, farmAnimalEventsCollectionRef, farmAnimalsCollectionRef } from '../../fire'
import { deleteField, doc, setDoc, updateDoc, writeBatch } from 'firebase/firestore'
import { useSnackbar } from 'notistack'
import { customAlphabet, nanoid } from 'nanoid'
import { AuthContext } from '../../../auth/AuthContext'

const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
const lowercase = 'abcdefghijklmnopqrstuvwxyz'
const numbers = '0123456789'
const customNanoId = customAlphabet(numbers + lowercase + uppercase, 10)

type ReproStatusType = 'heiferCalf' | 'bullCalf' | 'steer' | 'bull' | 'openCow' | 'pregCow' | 'pairedCow'
export type ReproStatusMap = Record<ReproStatusType, AnimalData[]>
const DEFAULT_STATUS_MAP: ReproStatusMap = {
    heiferCalf: [],
    bullCalf: [],
    steer: [],
    bull: [],
    openCow: [],
    pregCow: [],
    pairedCow: [],
}

export interface FarmAnimalsSvc {
    animals: AnimalData[],
    getAnimalById: (id: string) => AnimalData | null
    getAnimalByTagId: (tagId: string) => AnimalData | null
    animalsByReproStatus: ReproStatusMap
    deleteAnimalsByIds: (idsToDelete: string[]) => void,
    addAnimals: () => void,
    // markPreg: (motherId: string, dueDate: string) => void,
    // markBirth: (motherId: string, dob: Date, sex: AnimalSex) => void,
    // markWean: (calfId: string, weanCompleteDate: Date) => void,
    isInitialized: boolean,
}


export const useFarmAnimals = (): FarmAnimalsSvc => {
    const {id: farmId} = useContext(FarmMetaContext)
    const actorId = useContext(AuthContext)!.user!.uid
    // const [animals, setAnimals] = useState(_animals);
    const [data, loading, error] = useCollectionData<{ name: string }>(farmAnimalsCollectionRef(farmId) as any)
    const animals = useMemo<AnimalData[]>(() => {
        return data as any
    }, [data])
    const animalsById = useMemo(() => animals && keyBy(animals, a => a.id), [animals])
    const animalsByTagId = useMemo(() => animals && keyBy(animals.filter(a => !!a.etag), a => a.etag!.id), [animals])
    const animalsByReproStatus = useMemo(() => {
        if (animals) {
            // merging with default map to avoid null issues if any values are empty (reading .length from undefined)
            return {
                ...DEFAULT_STATUS_MAP, ...groupBy(animals, (a): ReproStatusType => {
                    if (!a.weaned) {
                        return a.sex === AnimalSex.FEMALE ? 'heiferCalf' : 'bullCalf'
                    } else {
                        if (a.sex === AnimalSex.FEMALE) {
                            if (!!a.reproductive?.calfPair) {
                                return 'pairedCow'
                            } else if (!!a.reproductive?.dueDate) {
                                return 'pregCow'
                            } else {
                                return 'openCow'
                            }
                        } else {
                            return a.sex === AnimalSex.MALE ? 'bull' : 'steer'
                        }
                    }
                })
            }
        } else {
            return DEFAULT_STATUS_MAP
        }
    }, [animals])
    const getAnimalById = useCallback((id: string) => animalsById[id], [animalsById])
    const getAnimalByTagId = useCallback((tagId: string) => animalsByTagId[tagId], [animalsByTagId])
    const {enqueueSnackbar} = useSnackbar()

    const deleteAnimalsByIds = useCallback(
        (idsToDelete: string[]) => {
            const batch = writeBatch(db)
            idsToDelete.forEach(id => {
                batch.delete(doc(farmAnimalsCollectionRef(farmId), id))
            })


            batch.commit().then(() => {
                const msg = idsToDelete.length === 1 ? 'animal removed' : 'animals removed'
                return enqueueSnackbar(msg, {variant: 'warning'})
            })
        },
        [farmId, enqueueSnackbar]
    )

    const addAnimals = useCallback(() => {
        Promise.all(
            chunk(generateAnimals(86), 20).map(async c => {
                    const batch = writeBatch(db)
                    await Promise.all(
                        c.map(async a => {
                            batch.set(doc(farmAnimalsCollectionRef(farmId), a.id), a)
                            const events = await generateEvents(actorId)
                            events.forEach(event => {
                                batch.set(doc(farmAnimalEventsCollectionRef(farmId, a.id), event.id), event)
                            })
                        })
                    )
                    return batch.commit()
                }
            )
        ).then(() => enqueueSnackbar('animals added', {variant: 'success'}))
    }, [enqueueSnackbar, actorId, farmId])

    const markPreg = useCallback((motherId: string, dueDate: string) => {

        // add timeline event to mother
        const event: AnimalTimelineEvent = {
            id: nanoid(),
            type: 'preg_detection',
            actorId,
            data: null,
            timestamp: new Date(),
        }
        Promise.all<void>([
            setDoc(doc(farmAnimalEventsCollectionRef(farmId, motherId), event.id), event),
            // update mother status
            updateDoc(doc(farmAnimalsCollectionRef(farmId), motherId), {reproductive: {dueDate}}),
        ]).then(() => enqueueSnackbar('Updated', {variant: 'success'}))
    }, [enqueueSnackbar, actorId, farmId])


    const markBirth = useCallback((motherId: string, dob: Date, sex: AnimalSex) => {
        const mother = getAnimalById(motherId)
        const motherLinkable: AnimalLinkable = {
            id: motherId,
            etag: mother.etag,
            photoUrl: mother.photoUrl,
        }
        const calf: AnimalData = {
            id: customNanoId(),
            dob,
            arrivalDate: dob,
            sex,
        }
        const motherBirthEvent: AnimalTimelineEvent = {
            id: nanoid(),
            type: 'calf_birth',
            actorId,
            data: {calfId: calf.id},
            timestamp: dob,
        }
        Promise.all<void>([
            // add calf doc
            setDoc(doc(farmAnimalsCollectionRef(farmId), calf.id), {...calf, mother: motherLinkable}),

            // add calf pair to mother
            updateDoc(doc(farmAnimalsCollectionRef(farmId), motherId), {reproductive: {calfPair: calf}}),

            // add mother timeline birth event
            setDoc(doc(farmAnimalEventsCollectionRef(farmId, motherId), motherBirthEvent.id), motherBirthEvent),
        ]).then(() => enqueueSnackbar('Updated', {variant: 'success'}))
    }, [enqueueSnackbar, getAnimalById, actorId, farmId])

    return {
        animals,
        getAnimalById,
        getAnimalByTagId,
        animalsByReproStatus,
        deleteAnimalsByIds,
        addAnimals,
        isInitialized: !!animals,
        // markPreg,
        // markWean,
        // markBirth,
    }
}




