// @mui
import { Box, Button, Container, Hidden, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
// auth
// layouts
//
import { Link as RLink } from 'react-router-dom'
import React from 'react'
import AmberWaves from 'src/assets/illustrations/AmberWaves'
import { PublicNavBar } from 'src/sections/landing/comp/NavBar'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'


// ----------------------------------------------------------------------
export const Hero = () => {
    // const theme = useTheme()
    // const smUp = useMediaQuery(theme.breakpoints.up('sm'))
    // const mdUp = useMediaQuery(theme.breakpoints.up('md'))
    // const lgUp = useMediaQuery(theme.breakpoints.up('lg'))
    // const mobile = useMediaQuery(theme.breakpoints.only('xs'))
    return (
        <Box sx={{
            height: '100vh',
            width: '100vw',
            display: 'flex',
            flexDirection: 'column',
            background: '#EEE1D0',
            position: 'relative',
            overflow: 'hidden',
        }}>
            <PublicNavBar/>
            <Box sx={{height: '40%', width: '100%', position: 'absolute', left: 0, bottom: 10, minWidth: 800}}>
                <AmberWaves/>
            </Box>
            <Hidden mdDown>
                <Box sx={{position: 'absolute', left: {md: '15%', lg: '20%'}, bottom: '18%'}}>
                    <img src="/assets/landing/cow_moon.svg" alt="Happy cow"
                         style={{objectFit: 'contain', height: '28vh'}}/>
                </Box>
            </Hidden>
            <Container sx={{position: 'relative', maxWidth: 1300, height: '100%'}}>
                <Box
                    sx={{
                        // background: 'rgba(142, 42, 242, .3)',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                    }}
                >
                    <Box sx={{flexGrow: 1, position: 'relative'}}>
                        <HeroGridNew/>
                    </Box>
                    <Box sx={{
                        height: {xs: 120, sm: 80, md: 70, lg: 70, xl: 70}, width: '100%',
                        // background: 'tomato'
                    }}/>
                </Box>

            </Container>
            <Box sx={{height: 11, width: '100%', position: 'absolute', background: 'white', left: 0, bottom: -1}}>
                <Box sx={{
                    height: 90,
                    width: '100%',
                    position: 'relative',
                    top: -89,
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    <Box sx={{
                        height: '100%',
                        width: '50%',
                        minWidth: 600,
                        background: 'url("/assets/landing/cow_meditate_blip_bg.svg") no-repeat',
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <Box sx={{
                            height: 80,
                            width: 80,
                            background: 'url("/assets/landing/zen_cow_no_petals.svg")  no-repeat 50% 50%',
                            mt: 1
                        }}></Box>
                    </Box>
                </Box>
            </Box>

        </Box>
    )
}


const HeroGridNew = () => {
    const theme = useTheme()
    const smUp = useMediaQuery(theme.breakpoints.up('sm'))
    const mdUp = useMediaQuery(theme.breakpoints.up('md'))
    const lgUp = useMediaQuery(theme.breakpoints.up('lg'))
    // const mobile = useMediaQuery(theme.breakpoints.only('xs'))
    return (
        <Grid container sx={{
            // background: 'rgba(42, 142, 242, .3)',
            height: '100%',
            // height: '100%',
            width: '100%',
            position: 'relative',
            justifyContent: 'center',
            // flexDirection: mdUp? 'row': 'column',
            // alignItems:'center',
            // flexDirection: 'column',
            overflow: 'hidden',
        }}>
            <Grid xs={12} sm={12} md={5} lg={6} sx={{
                // background: 'rgba(242, 42, 42, .3)',
                display: 'flex',
                alignItems: mdUp ? 'flex-start' : 'center',
                justifyContent: 'center',
                // alignItems: 'center',
                // height: '0%'
                // flexShrink: 1,
            }}>
                <Box sx={{
                    pt: {xs: 3, sm: 2, md: 2, lg: 5, xl: 5},
                    pb: {xs: 3, sm: 2, md: 2, lg: 5, xl: 5},
                    // background: 'rgba(242, 242, 42, .3)',

                    // background: 'rgba(242, 42, 42, .3)',
                }}>
                    <Typography variant={'h3'} color={'primary'}>
                        Herd & Pasture Insights
                    </Typography>
                    <Typography variant={'body1'} sx={{mt: 2, maxWidth: 500}}>
                        Resilient farms are built one well-informed decision at a time. Better insights,
                        better decisions.

                    </Typography>
                    <Hidden mdDown>
                        <Button to={'/early-access/signup'} variant={'contained'} size={'large'}
                                sx={{mt: 6, px: 8, borderRadius: 20}} component={RLink}>SIGN UP</Button>
                    </Hidden>
                </Box>

            </Grid>
            <Grid xs={12} sm={12} md={6} lg={6} sx={{
                // background: 'rgba(42, 42, 242, .3)',

                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
            }}>
                <Box sx={{
                    // background: 'rgba(42, 242, 242, .3)',
                    height: '100%',
                    maxWidth: {sm: 600, md: 600, lg: 800},
                    display: 'flex',
                    alignItems: 'center',
                }}>
                    <img src="/assets/landing/hero_tablet_RD.png" alt="metrics"
                         style={{
                             maxHeight: '100%',
                             maxWidth: '100%',
                             height: '100%',
                             // height: 1509,
                             // width: 1332,
                             width: '100%',
                             aspectRatio: '1509/1332',
                             objectFit: 'contain',
                             // margin: 'auto'
                         }}
                    />
                </Box>
            </Grid>
        </Grid>
    )
}
