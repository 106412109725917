import { memo } from 'react'
// @mui

// ----------------------------------------------------------------------

function AmberWaves() {
    return (
        <svg width={'100%'} height={'100%'} viewBox="0 0 1500 450" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio={'none'}>
            <path
                d="M1499.99 30.6756C1499.99 30.6756 1356.31 0 1277.5 0C1198.68 0 1194.57 27.3535 1124.79 44.1173C1055 60.8812 1013.95 90.8831 980.286 91.7645C946.624 92.646 884.228 108.528 837.432 131.471C790.635 154.413 678.977 163.285 630.536 164.582C582.094 165.88 395.314 146.471 374.79 144.704C354.266 142.942 300.488 125.292 264.365 116.47C228.241 107.647 187.189 118.232 149.424 103.236C111.658 88.2346 59.9343 67.0595 30.3755 73.2378H0V450H1499.99V30.6756Z"
                fill="#F3C1A6"/>
            <path
                d="M1499.99 91.7646C1499.99 91.7646 1457.3 105.884 1422 103.236C1386.69 100.587 1321.83 83.601 1268.46 103.565C1215.1 123.529 1191.29 153.527 1145.31 153.527C1099.34 153.527 1055 120 1016.41 118.233C977.828 116.47 915.428 172.054 837.431 182.644V446.466H1499.99V91.7646H1499.99Z"
                fill="#EF9D53"/>
            <path
                d="M765.184 194.115C765.184 194.115 710.177 179.995 628.894 149.116C547.615 118.233 549.257 104.999 481.933 112.94C414.609 120.881 312.394 165.88 312.394 165.88V293.821H765.184V194.115Z"
                fill="#EF9D53"/>
            <path
                d="M1499.86 211.467C1499.86 211.467 1380.9 169.093 1343.16 164.68C1305.43 160.266 1184.82 192.046 1137.24 205.286C1089.66 218.526 1013.36 255.606 987.105 249.424V446.28H1499.86V211.467Z"
                fill="#A6624D"/>
            <path
                d="M727.418 229.15C727.418 229.15 637.611 194.651 609.116 191.059C580.626 187.467 489.578 213.338 453.654 224.121C417.73 234.9 360.128 265.088 340.307 260.058V420.336H727.415V229.15H727.418Z"
                fill="#A6624D"/>
            <path
                d="M353.037 190.584C353.037 190.584 259.441 163.672 195.403 151.76C131.366 139.848 96.8831 129.258 64.8623 127.936C32.8416 126.614 0.00415039 127.936 0.00415039 127.936V269.111H346.739L353.041 190.58L353.037 190.584Z"
                fill="#EF9D53"/>
            <path
                d="M1499.99 248.822C1499.99 248.822 1424.46 261.397 1402.29 265.367C1380.12 269.338 1308.7 296.469 1268.67 291.176C1228.65 285.883 1220.03 264.045 1155.37 261.397C1090.72 258.748 1030.37 211.55 953.406 198.316C876.434 185.082 877.667 183.286 815.475 182.858C753.283 182.429 740.969 209.116 649.835 224.998C558.704 240.88 490.968 177.816 389.986 164.582C289 151.348 175.7 166.292 119.052 170.497C62.4004 174.702 0.00415039 170.497 0.00415039 170.497V449.996H1500V248.822H1499.99Z"
                fill="#005164"/>
            <path
                d="M1499.99 367.939C1499.99 367.939 1340.72 410.294 1304.59 402.353C1268.47 394.411 1128.9 338.823 1060.75 320.296C992.608 301.765 906.401 302.651 843.184 315.885C779.967 329.119 801.312 322.945 696.223 324.708C591.133 326.47 494.254 294.71 379.313 275.298C264.373 255.885 203.618 220.591 106.739 215.298C9.85969 210.005 0.00805664 190.593 0.00805664 190.593V450H1500V367.943L1499.99 367.939Z"
                fill="#013B49"/>
            {/*<g clipPath="url(#clip0_683_16493)">*/}
            {/*</g>*/}
            {/*<defs>*/}
            {/*    <clipPath id="clip0_683_16493">*/}
            {/*        <rect width="1500" height="450" fill="white"/>*/}
            {/*    </clipPath>*/}
            {/*</defs>*/}
        </svg>
    )
}

export default memo(AmberWaves)
