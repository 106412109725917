import { Helmet } from 'react-helmet-async'
// sections
import { TermsOfService } from 'src/sections/landing/TermsOfService'

// ----------------------------------------------------------------------

export default function TermsOfServicePage() {
    return (
        <>
            <Helmet>
                <title> Term of Service | Moooru</title>
            </Helmet>

            <TermsOfService/>
        </>
    )
}
