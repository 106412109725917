import {createContext, useCallback, useMemo} from "react";
import {useCollectionDataOnce} from "react-firebase-hooks/firestore";
import {farmDocRef} from "../../fire";
import {collection} from "firebase/firestore";
import {keyBy} from "lodash";


export interface FarmMember {
    id: string,
    name: string,
    photoUrl: string,
}

export interface FarmMembers {
    getById: (id: string) => FarmMember
}

export const FarmMembersContext = createContext<FarmMembers>(null as any)

export const useFarmMembers = (farmId: string): FarmMembers => {

    const [membersArr, _, error] = useCollectionDataOnce<FarmMember>(collection(farmDocRef(farmId), "members") as any)

    const memberMap = useMemo(() => membersArr ?keyBy(membersArr, m => m.id) :{},[membersArr])
    const getById = useCallback((id: string) => memberMap[id] || {
        id,
        name: id,
        photoUrl: "unknown",
    }, [memberMap])

    return {
        getById
    }
}

