import {createContext, useMemo} from 'react';
import {useAuthState} from 'react-firebase-hooks/auth';
// utils
//
import {AuthContextType, AuthUserType} from './types';
import {fireAuth} from "../data/fire";


// ----------------------------------------------------------------------


// ----------------------------------------------------------------------

export const AuthContext = createContext<AuthContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
    children: React.ReactNode;
};

export function AuthProvider({children}: AuthProviderProps) {
    const [authUser, initLoading] = useAuthState(fireAuth);

    const memoizedValue = useMemo(
        () => ({
            isInitialized: !initLoading,
            isAuthenticated: !!authUser,
            user: {...authUser, defaultFarmId: 'steeplecreek'} as AuthUserType,
        }),
        [initLoading, authUser]
    );

    return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
