import { Navigate, useRoutes, Link as RLink, Outlet } from 'react-router-dom'
// auth
import AuthGuard from '../auth/AuthGuard'
import GuestGuard from '../auth/GuestGuard'
// layouts
import CompactLayout from '../layouts/compact'
import DashboardLayout from '../layouts/dashboard'
// config
// import { PATH_AFTER_LOGIN } from '../config-global';
//
import { AnimalProfile, RosterPage, LoginPage, Page404, MetricsPage, EarlyAccessSignupPage } from './elements'
import { FarmPathsContext, useFarmPaths } from './paths'
import { useParams } from 'react-router'
import { FC, PropsWithChildren, useMemo } from 'react'
import { FarmMetaContext, useFarmMeta } from '../data/farm/meta'
import LoadingScreen from '../components/loading-screen'
import { Box, Link, Typography } from '@mui/material'
import { FarmMembersContext, useFarmMembers } from '../data/farm/members'
import { divide } from 'lodash'
import { alpha, createTheme, ThemeProvider, useTheme } from '@mui/material/styles'
import merge from 'lodash/merge'
import LandingPage from 'src/pages/LandingPage'
import AboutUsPage from 'src/pages/AboutUsPage'
import { PrivacyPolicy } from 'src/sections/landing/PrivacyPolicy'
import PrivacyPolicyPage from 'src/pages/PrivacyPolicyPage'
import TermsOfServicePage from 'src/pages/TermsOfServicePage'

// ----------------------------------------------------------------------

export default function Router() {
    return useRoutes([
        {
            path: '/',
            element: <LightThemeWrapper/>,
            children: [
                {element: <LandingPage/>, index: true},
                {path: 'about-us', element: <AboutUsPage/>},
                {path: 'privacy-policy', element: <PrivacyPolicyPage/>},
                {path: 'terms', element: <TermsOfServicePage/>},
                {
                    path: 'login',
                    element: (
                        <GuestGuard>
                            <LoginPage/>
                        </GuestGuard>
                    ),
                },
                {
                    path: 'early-access/signup',
                    element: (
                        <GuestGuard>
                            <EarlyAccessSignupPage/>
                        </GuestGuard>
                    ),
                },
            ],
        },
        {
            path: '/farms/:farmId',
            element: (
                <FarmPathsProvider>
                    <AuthGuard>
                        <FarmMetaProvider>
                            <DashboardLayout/>
                        </FarmMetaProvider>
                    </AuthGuard>
                </FarmPathsProvider>
            ),
            children: [
                {element: <Navigate to={'metrics'} replace/>, index: true},
                {path: 'metrics', element: <MetricsPage/>},
                {
                    path: 'records',
                    children: [
                        {index: true, element: <RosterPage/>},
                        {path: ':animalId', element: <AnimalProfile/>},
                    ],
                },
                {path: 'accounting', element: <MetricsPage/>},
                {path: 'activity', element: <MetricsPage/>},
                {path: 'channels', element: <MetricsPage/>},
                {path: 'projects', element: <MetricsPage/>},
                {path: 'pasture', element: <MetricsPage/>},
                // {
                //     path: 'user',
                //     children: [
                //         {element: <Navigate to="/dashboard/user/four" replace/>, index: true},
                //         {path: 'four', element: <PageFour/>},
                //         {path: 'five', element: <PageFive/>},
                //         {path: 'six', element: <PageSix/>},
                //     ],
                // },
            ],
        },
        {
            element: <CompactLayout/>,
            children: [{path: '404', element: <Page404/>}],
        },
        {path: '*', element: <Navigate to="/404" replace/>},
    ])
}
const PublicWrapper: FC = () => {
    const t = useTheme()
    return (
        <Box>

            <Outlet/>
        </Box>
    )
}

const LightThemeWrapper: FC = () => {
    const outerTheme = useTheme()
    const themeOptions = useMemo(() => ({palette: {mode: 'light'}}), [])
    const theme = createTheme(merge(outerTheme, themeOptions))
    return <ThemeProvider theme={theme}><Outlet/></ThemeProvider>
}

const FarmPathsProvider: FC<PropsWithChildren> = ({children}) => {
    const {farmId} = useParams<{ farmId: string }>()
    const farmPaths = useFarmPaths(farmId!)
    return (
        <FarmPathsContext.Provider value={farmPaths}>{children}</FarmPathsContext.Provider>
    )
}

const FarmMetaProvider: FC<PropsWithChildren> = ({children}) => {
    const {farmId} = useParams<{ farmId: string }>()
    const farmMeta = useFarmMeta(farmId!)
    const farmMembers = useFarmMembers(farmId!)


    if (!!farmMeta.error) {
        return (
            <Typography color={'error'}>{farmMeta.error}</Typography>
        )
    } else if (!farmMeta.isInitialized) {

        return <LoadingScreen/>
    } else {
        return (
            <FarmMembersContext.Provider value={farmMembers}>
                <FarmMetaContext.Provider value={farmMeta}>
                    {children}
                </FarmMetaContext.Provider>
            </FarmMembersContext.Provider>
        )

    }

}


