// @mui
import { Box, Button, Container, Hidden, Stack, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
// auth
// layouts
//
import { Link as RLink } from 'react-router-dom'
import React, { FC, useMemo, useState } from 'react'
import Logo from 'src/components/logo'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'


// ----------------------------------------------------------------------


const pricePerHeadMap: { [i: number]: number } = {
    20: 5,
    50: 4,
    100: 3,
}
export const Pricing: FC = () => {
    const theme = useTheme()
    const mdUp = useMediaQuery(theme.breakpoints.up('md'))
    const smUp = useMediaQuery(theme.breakpoints.up('sm'))
    const mobile = useMediaQuery(theme.breakpoints.only('xs'))

    const [highlighted, setHighlighted] = useState(100)
    const pricePerHead = useMemo<number>(() => pricePerHeadMap[highlighted]!!, [highlighted])

    const leftOffset = mdUp ? -26 : 0

    return (
        <Box sx={{pt: 8}}>
            <Container maxWidth={'sm'}>
                <Box id={'pricing'} sx={{textAlign: 'center', py: mobile ? 3 : 6}}>
                    <Typography variant={'h2'} sx={{color: '#8B847C'}}>Pricing</Typography>
                    <Typography variant={'body1'} sx={{pt: 2}}>
                        Simple pricing. No monthly fees or recurring charges.&nbsp;
                        <Hidden smDown><br/></Hidden>
                        Pay for what you use.
                    </Typography>
                </Box>
            </Container>
            <Container
                // maxWidth={smUp ? 'sm' : 'xs'}
                sx={{
                    maxWidth: {sm: '600px !important;', md: '700px !important;'},
                    // background: 'tomato',
                    // background: 'transparent'
                }}>
                <Grid container spacing={1} alignItems={'center'} justifyContent={'center'} sx={{px: 0}}>
                    <Grid xs={9} sm={7} md={6} lg={5}>
                        <Box sx={{position: 'relative'}}>
                            <img src="/assets/landing/yellow-tag.png" alt="tag"
                                 style={{objectFit: 'contain', width: '100%', position: 'relative', left: leftOffset}}/>
                            <Box sx={{
                                position: 'absolute',
                                top: 0,
                                left: leftOffset,
                                width: '100%',

                                height: '100%',
                                pl: '10%',
                                pt: '28%',
                                pr: '12%',
                                pb: '14%',
                                textAlign: 'center'
                            }}>
                                <Box sx={{
                                    width: '100%',
                                    height: '80%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <Typography variant={'h4'} sx={{pb: 5}}>$</Typography>
                                    <Typography variant={'h1'} sx={{
                                        fontSize: mobile ? '6rem !important;' : '8rem !important;',
                                        pr: 3
                                    }}>
                                        {pricePerHead}
                                    </Typography>
                                </Box>
                                <Box sx={{width: '100%', height: '20%'}}>
                                    <Typography variant={'subtitle2'} sx={{textTransform: 'uppercase'}}>
                                        Per Animal Etag
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box onMouseOut={() => setHighlighted(100)} sx={{
                            width: '66%',
                            // background: '#d3cabe',
                            // background: 'tomato',
                            mx: 'auto',
                            position: 'relative',
                            left: leftOffset,
                            textAlign: 'center',
                        }}>
                            <Typography variant={'caption'} sx={{fontWeight: 700, color: '#888'}}>ETAG
                                PACKS</Typography>
                            <TagPackLine packSize={20} dollarCost={100} highlightedPack={highlighted}
                                         updateHighlighted={setHighlighted}/>
                            <TagPackLine packSize={50} dollarCost={200} highlightedPack={highlighted}
                                         updateHighlighted={setHighlighted}/>
                            <TagPackLine packSize={100} dollarCost={300} highlightedPack={highlighted}
                                         updateHighlighted={setHighlighted}/>

                        </Box>
                    </Grid>
                    <Grid xs={12} md={6} lg={7} sx={{pt: mdUp ? 3 : 6}}>
                        <Stack spacing={4}>
                            <PriceHighlight
                                title={'Animal-Lifetime License'}
                                text={'One-time fee, lifetime value. ETag licenses are freely transferable to a new owner.'}
                                imgUrl={'/assets/landing/lifetime-license.svg'}
                            />
                            <PriceHighlight
                                title={'Pay as you go'}
                                text={'No recurring charges. No subscriptions to cancel. All Herd & Pasture tools are included. No hidden costs or surprise fees.'}
                                imgUrl={'/assets/landing/pay-per-use.svg'}
                            />
                            {/*<PriceHighlight*/}
                            {/*    title={'No Hidden Fees'}*/}
                            {/*    text={'All Herd & Pasture tools are included. No hidden costs or surprise fees.'}*/}
                            {/*    imgUrl={"/assets/landing/all-inclusive.svg"}*/}
                            {/*/>*/}
                            <PriceHighlight
                                title={'No Lock-In'}
                                text={'Export your data anytime. Never lose access to your herd records or pasture data.'}
                                imgUrl={'/assets/landing/all-inclusive.svg'}
                            />
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
            <Box sx={{mt: 20, mb: 15}}>
                <Container maxWidth={'md'} sx={{position: 'relative'}}>
                    {/*<Hidden mdDown>*/}
                    {/*</Hidden>*/}
                    <Box sx={{
                        height: mdUp ? '190%' : '170%',
                        width: '120%',
                        background: 'url("/assets/landing/price-circle.svg") no-repeat 50% 50%',
                        backgroundSize: 'contain',
                        position: 'absolute',
                        top: mdUp ? '-38%' : '-30%',
                        left: '-10%'
                    }}/>
                    <Box sx={{
                        width: '100%',

                        background: '#235568',
                        p: '10px',
                        borderRadius: 5,
                        display: 'flex',
                        position: 'relative',
                        alignItems: 'center',

                    }}>
                        <Box sx={{
                            position: 'relative',
                            background: '#EEE1D0',
                            borderRadius: mobile ? '30px' : '30px 0 0 30px',
                            height: '100%',
                            width: '100%',
                            p: 2,
                        }}>
                            <Stack direction={'column'} alignItems={'space-evenly'}
                                   sx={{minHeight: 230, position: 'relative'}}>
                                <Stack direction={'row'}>
                                    <Box sx={{mt: .4}}><Logo/></Box>
                                    <Typography variant={'h4'} color={'primary'} sx={{ml: 2}}>Starter Pack</Typography>
                                </Stack>
                                <Stack sx={{flexGrow: 1, my: 1}}>
                                    <Typography variant={'h5'} sx={{py: 1}}>Import your existing herd</Typography>
                                    <Typography variant={'body2'} sx={{my: mobile ? 2 : 0}}>
                                        Up to <strong>250 ETag licenses</strong> included to onboard your current
                                        herd.&nbsp;
                                        <Hidden smDown><br/></Hidden>
                                        Unused Starter Pack licenses expire 30 days after onboarding.
                                    </Typography>
                                </Stack>
                                <Button fullWidth to={'/early-access/signup'} variant={'contained'} size={'large'}
                                        sx={{
                                            // position: mobile?'relative':'absolute',
                                            // bottom: 0,
                                            // left: 0,
                                            width: '100%',
                                            borderRadius: 2,
                                            color: '#EEE1D0',
                                            mt: 1,
                                            mb: 0,
                                        }}
                                        component={RLink}>GET STARTED</Button>

                            </Stack>
                            <Hidden smUp>
                                <Box
                                    sx={{
                                        position: 'absolute', top: 0, right: 0,
                                        // background: 'tomato',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        p: 1,
                                    }}
                                >
                                    <Typography color={'primary'} variant={'h3'} sx={{pb: 2}}>$</Typography>
                                    <Typography color={'primary'} variant={'h1'}
                                                sx={{fontSize: '4rem !important;', pr: mdUp ? 2 : 1}}>5</Typography>
                                </Box>
                            </Hidden>
                        </Box>
                        <Hidden smDown>
                            <Box sx={{
                                mx: mdUp ? 5 : 3,
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Typography variant={'h3'} sx={{pb: 5, color: '#EEE1D0'}}>$</Typography>
                                <Typography variant={'h1'}
                                            sx={{
                                                fontSize: '9rem !important;',
                                                color: '#EEE1D0',
                                                pr: mdUp ? 2 : 1
                                            }}>5</Typography>


                            </Box>
                        </Hidden>


                        <Box sx={{
                            height: '40%',
                            width: '45%',
                            background: 'url("/assets/landing/price-underlines.svg") no-repeat 50% 50%',
                            backgroundSize: 'contain',
                            position: 'absolute',
                            bottom: '-110px',
                            left: '35%'
                        }}/>

                        <Hidden mdDown>
                            <Box sx={{
                                height: '50%',
                                width: '30%',
                                background: 'url("/assets/landing/price-arrow.svg") no-repeat 50% 50%',
                                backgroundSize: 'contain',
                                position: 'absolute',
                                top: '-50%',
                                right: '-20%'
                            }}/>
                        </Hidden>
                    </Box>
                </Container>

            </Box>
        </Box>
    )
}

const PriceHighlight: FC<{ title: string, text: string, imgUrl: string }> = ({title, text, imgUrl}) => {
    return (
        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Box sx={{width: 70, textAlign: 'right', mt: 1}}>
                <img src={imgUrl} alt={title} style={{objectFit: 'contain'}}/>
            </Box>
            <Box sx={{width: '100%', ml: 3}}>
                <Typography variant={'subtitle1'}>{title}</Typography>
                <Typography variant={'body2'}>{text}</Typography>
            </Box>
        </Box>
    )
}

const TagPackLine: FC<{
    packSize: number, // functions as id
    dollarCost: number,
    highlightedPack: number,
    updateHighlighted: (packSize: number) => void
}> = ({packSize, dollarCost, highlightedPack, updateHighlighted}) => {

    const isHighlighted = useMemo(() => packSize === highlightedPack, [packSize, highlightedPack])
    const highlightColor = '#333'
    const defaultColor = '#777'
    return (
        <Box onMouseOver={() => updateHighlighted(packSize)}
             sx={{
                 display: 'flex',
                 justifyContent: 'space-between',
                 py: .5,
                 my: 0,
                 fontWeight: isHighlighted ? 800 : 500,
                 color: isHighlighted ? highlightColor : defaultColor,
             }}>
            <Typography sx={{fontWeight: isHighlighted ? 800 : 500}}>{packSize} pack</Typography>
            <Box sx={{
                flexGrow: 1,
                borderBottom: 'dotted 3px #333',
                borderColor: isHighlighted ? highlightColor : defaultColor,
                mb: '4px',
                mx: 1
            }}/>
            <Typography sx={{fontWeight: isHighlighted ? 800 : 500}}>${dollarCost}</Typography>
        </Box>
    )
}