// @mui
import { Box, Typography } from '@mui/material'
// auth
// layouts
//
import React, { FC } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'


// ----------------------------------------------------------------------

export const SheepComingSoon: FC = () => {
    const theme = useTheme()
    const horizontal = useMediaQuery(theme.breakpoints.up('md'))
    const mobile = useMediaQuery(theme.breakpoints.only('xs'))
    return (
        <Box sx={{background: '#164257', position: 'relative', width: '100%', pointerEvents: 'none'}}>
            <Box sx={{textAlign: 'center', py: horizontal ? 32 : 40}}>
                <Typography variant={'h1'} sx={{color: '#CDCAC7'}}>Counting Sheep</Typography>
                <Typography variant={'h5'} sx={{color: '#AAA6A2', pt: 2}}>Coming Soon</Typography>
            </Box>
            <Box sx={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                height: horizontal ? '50%' : '30%',
                display: mobile ? 'none' : 'block'
            }}>
                <img src="/assets/landing/sheep-bl.svg" alt="sheep"
                     style={{objectFit: 'contain', height: '100%'}}/>
            </Box>
            <Box sx={{position: 'absolute', bottom: 0, right: 0, height: horizontal ? '50%' : '30%'}}>
                <img src="/assets/landing/sheep-br.svg" alt="sheep"
                     style={{objectFit: 'contain', height: '100%'}}/>
            </Box>
            <Box sx={{position: 'absolute', top: '8%', right: '5%', height: horizontal ? '30%' : '20%'}}>
                <img src="/assets/landing/sheep-tr.svg" alt="sheep"
                     style={{objectFit: 'contain', height: '60%'}}/>
            </Box>
            <Box sx={{position: 'absolute', top: '8%', left: '5%', height: horizontal ? '35%' : '25%'}}>
                <img src="/assets/landing/sheep-tl.svg" alt="sheep"
                     style={{objectFit: 'contain', height: '100%'}}/>
            </Box>
        </Box>
    )
}