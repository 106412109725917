// @mui
import { Alert, Tooltip, Stack, Typography, Link, Box } from '@mui/material'
// auth
import { useAuthContext } from '../../auth/useAuthContext'
// layouts
import LoginLayout from '../../layouts/login'
//
import AuthLoginForm from './AuthLoginForm'
import AuthWithSocial from './AuthWithSocial'
import Logo from 'src/components/logo'

// ----------------------------------------------------------------------

export default function Login() {

    return (
        <LoginLayout>
            <Stack spacing={2} sx={{mb: 5, position: 'relative', textAlign: 'center'}}>
                <Logo
                    customColor={'#888'}
                    sx={{
                        mx: 'auto',
                        height: 60,
                        width: 60,
                    }}
                />
                <Typography variant="subtitle2" sx={{textTransform: 'uppercase', color: '#999'}}>
                    Sign in to Moooru
                </Typography>
            </Stack>

            <AuthLoginForm/>

            {/*<AuthWithSocial/>*/}
        </LoginLayout>
    )
}
