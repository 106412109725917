import { forwardRef } from 'react'
import { Link as RouterLink } from 'react-router-dom'
// @mui
import { useTheme } from '@mui/material/styles'
import { Box, Link, BoxProps } from '@mui/material'
import { useSettingsContext } from '../settings'

// ----------------------------------------------------------------------

export interface WordmarkProps extends BoxProps {
    disabledLink?: boolean;
    customColor?: string;
}

const Wordmark = forwardRef<HTMLDivElement, WordmarkProps>(
    ({disabledLink = false, customColor, sx, ...other}, ref) => {
        const theme = useTheme()
        const {themeMode} = useSettingsContext()

        const PRIMARY_LIGHT = theme.palette.primary.lighter

        const PRIMARY_MAIN = theme.palette.primary.main

        const PRIMARY_DARK = theme.palette.primary.dark

        const logoColor = customColor ?? (themeMode === 'light' ? PRIMARY_MAIN : PRIMARY_LIGHT)

        // OR using local (public folder)
        // -------------------------------------------------------
        // const logo = (
        //   <Box
        //     component="img"
        //     src="/logo/logo_single.svg" => your path
        //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
        //   />
        // );

        const logo = (
            <Box
                ref={ref}
                component="div"
                sx={{
                    width: 85,
                    height: 14,
                    // display: 'inline-flex',
                    alignItems:'center',
                    ...sx,
                }}
                {...other}
            >
                <svg viewBox="0 0 85 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M7.67233 6.30404C7.67233 5.15204 7.52033 4.33604 7.21633 3.85604C6.92833 3.37604 6.42433 3.13604 5.70433 3.13604C5.48033 3.13604 5.24833 3.15204 5.00833 3.18404C4.76833 3.20004 4.53633 3.22404 4.31233 3.25604V13H0.736328V0.80804C1.04033 0.72804 1.39233 0.64804 1.79233 0.56804C2.20833 0.472039 2.64033 0.392039 3.08833 0.32804C3.55233 0.248039 4.02433 0.192039 4.50433 0.16004C4.98433 0.11204 5.45633 0.0880394 5.92033 0.0880394C6.83233 0.0880394 7.56833 0.208039 8.12833 0.44804C8.70433 0.67204 9.17633 0.94404 9.54433 1.26404C10.0563 0.89604 10.6403 0.60804 11.2963 0.40004C11.9683 0.192039 12.5843 0.0880394 13.1443 0.0880394C14.1523 0.0880394 14.9763 0.232038 15.6163 0.520039C16.2723 0.792039 16.7923 1.18404 17.1763 1.69604C17.5603 2.20804 17.8243 2.81604 17.9683 3.52004C18.1123 4.22404 18.1843 5.00804 18.1843 5.87204V13H14.6083V6.30404C14.6083 5.15204 14.4563 4.33604 14.1523 3.85604C13.8643 3.37604 13.3603 3.13604 12.6403 3.13604C12.4483 3.13604 12.1763 3.18404 11.8243 3.28004C11.4883 3.37604 11.2083 3.49604 10.9843 3.64004C11.0963 4.00804 11.1683 4.40004 11.2003 4.81604C11.2323 5.21604 11.2483 5.64804 11.2483 6.11204V13H7.67233V6.30404Z"
                        style={{fill: logoColor}}/>
                    <path
                        d="M32.8796 6.66404C32.8796 7.65604 32.7356 8.56804 32.4476 9.40004C32.1596 10.216 31.7436 10.92 31.1996 11.512C30.6556 12.088 29.9996 12.536 29.2316 12.856C28.4796 13.176 27.6316 13.336 26.6876 13.336C25.7596 13.336 24.9116 13.176 24.1436 12.856C23.3916 12.536 22.7436 12.088 22.1996 11.512C21.6556 10.92 21.2316 10.216 20.9276 9.40004C20.6236 8.56804 20.4716 7.65604 20.4716 6.66404C20.4716 5.67204 20.6236 4.76804 20.9276 3.95204C21.2476 3.13604 21.6796 2.44004 22.2236 1.86404C22.7836 1.28804 23.4396 0.840039 24.1916 0.520039C24.9596 0.200039 25.7916 0.0400391 26.6876 0.0400391C27.5996 0.0400391 28.4316 0.200039 29.1836 0.520039C29.9516 0.840039 30.6076 1.28804 31.1516 1.86404C31.6956 2.44004 32.1196 3.13604 32.4236 3.95204C32.7276 4.76804 32.8796 5.67204 32.8796 6.66404ZM29.2316 6.66404C29.2316 5.56004 29.0076 4.69604 28.5596 4.07204C28.1276 3.43204 27.5036 3.11204 26.6876 3.11204C25.8716 3.11204 25.2396 3.43204 24.7916 4.07204C24.3436 4.69604 24.1196 5.56004 24.1196 6.66404C24.1196 7.76804 24.3436 8.64804 24.7916 9.30404C25.2396 9.94404 25.8716 10.264 26.6876 10.264C27.5036 10.264 28.1276 9.94404 28.5596 9.30404C29.0076 8.64804 29.2316 7.76804 29.2316 6.66404Z"
                        style={{fill: logoColor}}/>
                    <path
                        d="M47.0978 6.66404C47.0978 7.65604 46.9538 8.56804 46.6658 9.40004C46.3778 10.216 45.9618 10.92 45.4178 11.512C44.8738 12.088 44.2178 12.536 43.4498 12.856C42.6978 13.176 41.8498 13.336 40.9058 13.336C39.9778 13.336 39.1298 13.176 38.3618 12.856C37.6098 12.536 36.9618 12.088 36.4178 11.512C35.8738 10.92 35.4498 10.216 35.1458 9.40004C34.8418 8.56804 34.6898 7.65604 34.6898 6.66404C34.6898 5.67204 34.8418 4.76804 35.1458 3.95204C35.4658 3.13604 35.8978 2.44004 36.4418 1.86404C37.0018 1.28804 37.6578 0.840039 38.4098 0.520039C39.1778 0.200039 40.0098 0.0400391 40.9058 0.0400391C41.8178 0.0400391 42.6498 0.200039 43.4018 0.520039C44.1698 0.840039 44.8258 1.28804 45.3698 1.86404C45.9138 2.44004 46.3378 3.13604 46.6418 3.95204C46.9458 4.76804 47.0978 5.67204 47.0978 6.66404ZM43.4498 6.66404C43.4498 5.56004 43.2258 4.69604 42.7778 4.07204C42.3458 3.43204 41.7218 3.11204 40.9058 3.11204C40.0898 3.11204 39.4578 3.43204 39.0098 4.07204C38.5618 4.69604 38.3378 5.56004 38.3378 6.66404C38.3378 7.76804 38.5618 8.64804 39.0098 9.30404C39.4578 9.94404 40.0898 10.264 40.9058 10.264C41.7218 10.264 42.3458 9.94404 42.7778 9.30404C43.2258 8.64804 43.4498 7.76804 43.4498 6.66404Z"
                        style={{fill: logoColor}}/>
                    <path
                        d="M61.3159 6.66404C61.3159 7.65604 61.1719 8.56804 60.8839 9.40004C60.5959 10.216 60.1799 10.92 59.6359 11.512C59.0919 12.088 58.4359 12.536 57.6679 12.856C56.9159 13.176 56.0679 13.336 55.1239 13.336C54.1959 13.336 53.3479 13.176 52.5799 12.856C51.8279 12.536 51.1799 12.088 50.6359 11.512C50.0919 10.92 49.6679 10.216 49.3639 9.40004C49.0599 8.56804 48.9079 7.65604 48.9079 6.66404C48.9079 5.67204 49.0599 4.76804 49.3639 3.95204C49.6839 3.13604 50.1159 2.44004 50.6599 1.86404C51.2199 1.28804 51.8759 0.840039 52.6279 0.520039C53.3959 0.200039 54.2279 0.0400391 55.1239 0.0400391C56.0359 0.0400391 56.8679 0.200039 57.6199 0.520039C58.3879 0.840039 59.0439 1.28804 59.5879 1.86404C60.1319 2.44004 60.5559 3.13604 60.8599 3.95204C61.1639 4.76804 61.3159 5.67204 61.3159 6.66404ZM57.6679 6.66404C57.6679 5.56004 57.4439 4.69604 56.9959 4.07204C56.5639 3.43204 55.9399 3.11204 55.1239 3.11204C54.3079 3.11204 53.6759 3.43204 53.2279 4.07204C52.7799 4.69604 52.5559 5.56004 52.5559 6.66404C52.5559 7.76804 52.7799 8.64804 53.2279 9.30404C53.6759 9.94404 54.3079 10.264 55.1239 10.264C55.9399 10.264 56.5639 9.94404 56.9959 9.30404C57.4439 8.64804 57.6679 7.76804 57.6679 6.66404Z"
                        style={{fill: logoColor}}/>
                    <path
                        d="M71.334 3.54404C71.014 3.46404 70.638 3.38404 70.206 3.30404C69.774 3.20804 69.31 3.16004 68.814 3.16004C68.59 3.16004 68.318 3.18404 67.998 3.23204C67.694 3.26404 67.462 3.30404 67.302 3.35204V13H63.726V1.04804C64.366 0.824039 65.118 0.616039 65.982 0.424039C66.862 0.21604 67.838 0.11204 68.91 0.11204C69.102 0.11204 69.334 0.12804 69.606 0.16004C69.878 0.17604 70.15 0.208039 70.422 0.25604C70.694 0.288039 70.966 0.33604 71.238 0.40004C71.51 0.44804 71.742 0.512039 71.934 0.592039L71.334 3.54404Z"
                        style={{fill: logoColor}}/>
                    <path
                        d="M84.267 12.568C83.659 12.744 82.875 12.904 81.915 13.048C80.955 13.208 79.947 13.288 78.891 13.288C77.819 13.288 76.923 13.144 76.203 12.856C75.499 12.568 74.939 12.168 74.523 11.656C74.107 11.128 73.811 10.504 73.635 9.78404C73.459 9.06404 73.371 8.27204 73.371 7.40804V0.37604H76.947V6.97604C76.947 8.12804 77.099 8.96004 77.403 9.47204C77.707 9.98404 78.275 10.24 79.107 10.24C79.363 10.24 79.635 10.232 79.923 10.216C80.211 10.184 80.467 10.152 80.691 10.12V0.37604H84.267V12.568Z"
                        style={{fill: logoColor}}/>
                </svg>
            </Box>
        )

        if (disabledLink) {
            return logo
        }

        return (
            <Link component={RouterLink} to="/" sx={{display: 'contents'}}>
                {logo}
            </Link>
        )
    }
)

export default Wordmark
