import {createContext, useCallback} from "react";
import {useDocumentData} from "react-firebase-hooks/firestore";
import {db, farmDocRef} from "../../fire";


export interface FarmMeta {
    id: string,
    name: string,
    city: string,
    state: string,
    isInitialized: boolean,
    error?: string,
}

export const FarmMetaContext = createContext<FarmMeta>(null as any)

export const useFarmMeta = (farmId: string): FarmMeta => {

    const [data, _, error] = useDocumentData<{ name: string, city: string, state: string }>(farmDocRef(farmId) as any)

    return {
        id: farmId,
        name: data?.name || 'loading',
        city: data?.city || 'loading',
        state: data?.state || 'loading',
        isInitialized: !!data,
        error: error?.code
    }
}

