// @mui
import {alpha, styled} from '@mui/material/styles';
import {Box, Typography} from '@mui/material';
// auth
import {useAuthContext} from '../../../auth/useAuthContext';
// components
import {CustomAvatar} from '../../../components/custom-avatar';
import {useContext, useMemo} from "react";
import {FarmMetaContext} from "../../../data/farm/meta/useFarmMeta";
import {FarmMembersContext} from "../../../data/farm/members";

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

export default function NavAccount() {
    const {user} = useAuthContext();
    const {getById} = useContext(FarmMembersContext)
    const principal = useMemo(() => getById(user.uid), [getById, user.uid])
    const {name: farmName} = useContext(FarmMetaContext)

    return (
        <StyledRoot>
            <CustomAvatar src={user?.photoURL} alt={user?.displayName} name={user?.displayName}/>

            <Box sx={{ml: 2, minWidth: 0}}>
                <Typography variant="subtitle2" noWrap>
                    {principal.name}
                </Typography>

                <Typography variant="body2" noWrap sx={{color: 'text.secondary'}}>
                    {farmName}
                    {/*{user?.role}*/}
                </Typography>
            </Box>
        </StyledRoot>
    );
}
