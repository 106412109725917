// @mui
import {
    AppBar,
    Box,
    Button,
    Container,
    FormControl,
    Hidden,
    IconButton,
    Link, Menu, MenuItem,
    Stack,
    Toolbar,
    Typography
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
// auth
// layouts
//
import { Link as RLink } from 'react-router-dom'
import React, { FC } from 'react'
import Logo from 'src/components/logo'
import Wordmark from 'src/components/logo/Wordmark'
import AmberWaves from 'src/assets/illustrations/AmberWaves'
import { m } from 'framer-motion'
import { HashLink } from 'react-router-hash-link'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import Iconify from 'src/components/iconify'


// ----------------------------------------------------------------------

export const PublicNavBar = () => {
    const theme = useTheme()
    const horizontal = useMediaQuery(theme.breakpoints.up('md'))
    const mobile = useMediaQuery(theme.breakpoints.only('xs'))
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget)
    }

    const handleCloseNavMenu = () => {
        setAnchorElNav(null)
    }

    return (
        <Container maxWidth={'lg'}>
            <Toolbar disableGutters sx={{
                // background: 'pink',
                // borderBottom: 'thin solid red'
                // width: '94%',
                // minWidth: 500,
                height: mobile ? 80 : 140,
                margin: 'auto',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                position: 'relative',
                maxWidth: '100vw', overflow: 'hidden'
            }}>

                <Stack direction={'row'} spacing={20} sx={{mt: 2}}>
                    <Logo sx={{height: 40, width: 40}}/>
                    <Wordmark sx={{ml: 2, height: 40, width: 80, mt: .5}}/>
                </Stack>
                <Box sx={{display: {xs: 'flex', md: 'none'}, position: 'relative'}}>
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenNavMenu}
                        color="inherit"
                    >
                        <Iconify icon={'tabler:menu-2'} color={theme.palette.primary.main}
                                 sx={{height: 30, width: 30}}/>
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        sx={{
                            display: {xs: 'block', md: 'none'},
                        }}
                    >
                        <MenuItem onClick={handleCloseNavMenu}>
                            <Link component={RLink} to={'/about-us'}>About Us</Link>
                        </MenuItem>
                        <MenuItem onClick={handleCloseNavMenu}>
                            <Link component={HashLink} smooth to={'/#pricing'} preventScrollReset={true}>
                                Pricing
                            </Link>
                        </MenuItem>
                        <MenuItem onClick={handleCloseNavMenu}>
                            <Link component={RLink} to={'/login'}>Log In</Link>
                        </MenuItem>
                        <MenuItem onClick={handleCloseNavMenu}>
                            <Link component={RLink} to={'/early-access/signup'}>Sign Up</Link>
                        </MenuItem>

                    </Menu>
                </Box>
                <Stack direction={'row'} spacing={3} sx={{alignItems: 'center', display: {xs: 'none', md: 'flex'}}}>
                    <Link sx={{px: 1}} component={RLink} to={'/about-us'}>About Us</Link>
                    <Link sx={{px: 1}} component={HashLink} smooth to={'/#pricing'}
                          preventScrollReset={true}>Pricing</Link>
                    <Stack direction={'row'} spacing={1} sx={{alignItems: 'center'}}>
                        <Button variant={'outlined'} to={'/login'} component={RLink} sx={{px: 5}}>Login</Button>
                        <Button variant={'contained'} to={'/early-access/signup'} component={RLink}
                                sx={{px: 5, color: '#EEE1D0'}}>Sign Up</Button>
                    </Stack>
                </Stack>
            </Toolbar>
        </Container>

    )
}
