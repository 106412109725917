import { Suspense, lazy, ElementType } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

export const LoginPage = Loadable(lazy(() => import('../pages/LoginPage')));
export const EarlyAccessSignupPage = Loadable(lazy(() => import('../pages/EarlyAccessSignupPage')));

export const RosterPage = Loadable(lazy(() => import('../pages/farm/cattle/roster/RosterPage')));
export const MetricsPage = Loadable(lazy(() => import('../pages/farm/cattle/metrics/GeneralAnalyticsPage')));
// export const MetricsPage = Loadable(lazy(() => import('../pages/farm/cattle/metrics/MetricsPage')));
export const AnimalProfile = Loadable(lazy(() => import('../pages/farm/cattle/animal/AnimalProfile')));

export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
