import {createContext, useCallback} from "react";
import {id} from "date-fns/locale";


export interface FarmPaths {
    root: string,
    local: FarmPathsLocal,
    localToExternal: (localPath: string) => string,
    animalLink: (id: string) => string,
}

export interface FarmPathsLocal {
    animalLink: (id: string) => string,
    metrics: string,
    pasture: string,
    channels: string,
    projects: string,
    accounting: string,
    activity: string,
    records: string,
}

export const FarmPathsContext = createContext<FarmPaths>(null as any)

export const useFarmPaths = (farmId: string): FarmPaths => {
    const root = `/farms/${farmId}`
    const records = `/records`
    const metrics = `/metrics`
    const pasture = `/pasture`
    const channels = `/channels`
    const projects = `/projects`
    const accounting = `/accounting`
    const activity = `/activity`
    const localToExternal = useCallback((localPath: string) => root + localPath, [root])
    const local = {
        records,
        metrics,
        pasture,
        channels,
        projects,
        accounting,
        activity,
        animalLink: useCallback((idOrTagId: string) => `${records}/${idOrTagId}`, [records]),
    }
    const animalLink = useCallback(
        (idOrTagId: string) => localToExternal(local.animalLink(idOrTagId)),
        [local.animalLink, localToExternal]
    )
    return {
        root,
        localToExternal,
        animalLink,
        local,
    }
}

// function path(root: string, sublink: string) {
//   return `${root}${sublink}`;
// }
//
// const ROOTS_DASHBOARD = '/dashboard';
//
// // ----------------------------------------------------------------------
//
// export const PATH_AUTH = {
//   login: '/login',
// };
//
// export const PATH_DASHBOARD = {
//   root: ROOTS_DASHBOARD,
//   records: path(ROOTS_DASHBOARD, '/animal/records'),
//   metrics: path(ROOTS_DASHBOARD, '/metrics'),
//   three: path(ROOTS_DASHBOARD, '/three'),
//   user: {
//     root: path(ROOTS_DASHBOARD, '/user'),
//     four: path(ROOTS_DASHBOARD, '/user/four'),
//     five: path(ROOTS_DASHBOARD, '/user/five'),
//     six: path(ROOTS_DASHBOARD, '/user/six'),
//   },
// };
