import React, {FC} from 'react';
import {Paper, Typography} from "@mui/material";
import {ETag, getTagHexColors, TagColor} from "../../data/farm/animals";

export const EtagDisplay: FC<{
    etag?: ETag, width: number, fontSize: number, arrowSize?: number
}> = ({etag, width, fontSize, arrowSize = 5}) => {

    const {textColor, bgColor} = etag?.color ? getTagHexColors(etag.color) : {
        textColor: '#777',
        bgColor:'#CCC'
    }


    return (
        <Paper
            elevation={3}
            sx={{
                background: bgColor,
                borderRadius: '2px',
                position: 'relative',
                width,
                textAlign: 'center',
                '&:after': {
                    bottom: '100%',
                    left: '50%',
                    border: 'solid transparent',
                    content: '""',
                    height: 0,
                    width: 0,
                    position: 'absolute',
                    pointerEvents: 'none',


                    ///

                    borderColor: 'rgba(136, 183, 213, 0)',
                    borderBottomColor: bgColor,
                    borderWidth: `${arrowSize}px`,
                    marginLeft: `-${arrowSize}px`,
                }
            }}>
            <Typography sx={{fontWeight: 600, color: textColor, fontSize}}>
                {etag?.id || 'NO_TAG'}
            </Typography>
        </Paper>
    );
}

